import {
    Typography,
    TypographyProps,
} from '@mui/material';
import React, {
    ReactNode,
} from 'react';

import { Markdown } from 'src/app/components/Markdown';

const COPYRIGHT_TEXT = `Copyright © [Velocity, A Managed Solutions Company](https://velocitymsc.com/) ${new Date().getFullYear()}.`;

export function Copyright(props: TypographyProps): ReactNode {
    return (
        <Typography
            align="center"
            color="text.secondary"
            component="div"
            variant="body2"
            {...props}
        >
            <Markdown>
                {COPYRIGHT_TEXT}
            </Markdown>
        </Typography>
    );
}
