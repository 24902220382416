import { Box } from '@mui/material';
import React, {
    ReactNode,
} from 'react';

import banner from 'src/app/public/velocity_in_room_solutions.png';

export function VelocityInRoomSolutionsBanner(): ReactNode {
    return (
        <Box
            alt="Velocity In-Room Solutions"
            component="img"
            src={banner}
            sx={{
                height: 'auto',
                maxWidth: 350,
                minWidth: 100,
            }}
        />
    );
}
