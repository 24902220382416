import { Close as CloseIcon } from '@mui/icons-material';
import {
    Alert,
    AlertProps,
    IconButton,
    Snackbar,
    SnackbarProps,
} from '@mui/material';
import React, {
    ReactNode,
} from 'react';

export type PanPortalSnackbarAlertProps = Omit<SnackbarProps, 'children'> & {
    alertProps?: Omit<AlertProps, 'children'>;
    children: AlertProps['children'];
};

export function PanPortalSnackbarAlert({
    alertProps,
    children,
    ...snackbarProps
}: PanPortalSnackbarAlertProps): ReactNode {
    const action: AlertProps['action'] = (
        <IconButton
            aria-label="close"
            color="inherit"
            onClick={alertProps?.onClose}
            size="small"
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            {...snackbarProps}
        >
            <Alert
                action={action}
                severity="error"
                sx={{
                    width: '100%',
                }}
                variant="filled"
                {...alertProps}
            >
                {children}
            </Alert>
        </Snackbar>
    );
}
