import { DUMMY_KEY } from 'src/app/components/Captcha';

export const IS_PROD = import.meta.env.PROD;

export const CAPTCHA_KEY = import.meta.env.VITE_CAPTCHA_KEY ?? (IS_PROD ? '' : DUMMY_KEY);

const DEFAULT_API_URL = new URL(window.location.origin);
DEFAULT_API_URL.port = '3000';

// || over ?? so the api is less likely to break on misconfigured variable
export const API_URL = import.meta.env.VITE_API_URL || DEFAULT_API_URL.origin;
