import {
    useEffect,
    useState,
} from 'react';

import { getClientInfo } from 'src/app/helpers/api';

const clientInfo = getClientInfo();

export function useClientAddress() {
    const [ clientAddress, setClientAddress ] = useState('');

    useEffect(
        () => {
            (async () => {
                const { ipAddress = '' } = await clientInfo || {};
                if (ipAddress) {
                    setClientAddress(`Your IP Address: ${ipAddress}`);
                }
            })();
        },
        [],
    );

    return clientAddress;
}
