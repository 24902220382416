import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import React, {
    FormEvent,
    ReactNode,
    useCallback,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Captcha } from 'src/app/components/Captcha';
import { Copyright } from 'src/app/components/Copyright';
import { Markdown } from 'src/app/components/Markdown';
import { BuildingUnitAutocomplete } from 'src/app/components/Registration/BuildingUnitAutocomplete';
import { EulaCheckbox } from 'src/app/components/Registration/EulaCheckbox';
import { LocationAddress } from 'src/app/components/Registration/LocationAddress';
import { PrivacyPolicyButton } from 'src/app/components/Registration/PrivacyPolicyButton';
import { VelocityInRoomSolutionsBanner } from 'src/app/components/VelocityInRoomSolutionsBanner';
import { CAPTCHA_KEY } from 'src/app/config';
import { register } from 'src/app/helpers/register';
import { useLocationSettings } from 'src/app/hooks/useLocationSettings';
import { useSetSnackbarContent } from 'src/app/hooks/useSnackbar';
import { useSetIsSpinnerOpen } from 'src/app/hooks/useSpinner';
import background from 'src/app/public/background.jpg';

// TODO: Need to get this from the Support Team (Tomas and/or Mike Rehard)
const SUPPORT_TEXT_INFO_DEFAULT = '';

export function RegistrationForm(): ReactNode {
    const navigate = useNavigate();

    const setIsSpinnerOpen = useSetIsSpinnerOpen();
    const setSnackbarContent = useSetSnackbarContent();

    const {
        locationId,
        propertyName,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        supportInfoText = SUPPORT_TEXT_INFO_DEFAULT,
    } = useLocationSettings();

    const [ isRegisterButtonEnabled, setIsRegisterButtonEnabled ] = useState<boolean>(false);
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');

    const handleEulaToggleChange = useCallback(
        (value: boolean) => {
            setIsRegisterButtonEnabled(value);
        },
        [],
    );

    const handlePhoneNumberChange = useCallback(
        (value: string): void => setPhoneNumber(value),
        [],
    );

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>): Promise<void> => {
            event.preventDefault();
            if (!isRegisterButtonEnabled) {
                return;
            }

            setIsRegisterButtonEnabled(false);
            setIsSpinnerOpen(true);

            const formData = new FormData(event.currentTarget);
            register(formData)
                .then(async response => {
                    if (!response.ok) {
                        const { message } = await response.json();
                        if (Array.isArray(message)) {
                            throw new Error(`- ${message.join('\n- ')}`);
                        } else if (message) {
                            throw new Error(message);
                        }
                        throw new Error('Internal Server Error occurred.');
                    }
                    navigate('/success');
                })
                .catch(err => {
                    console.error(err);
                    const message = err?.message ?? 'Unknown Error occurred.';
                    if (message === 'Failed Captcha') {
                        window.turnstile?.reset();
                    }
                    setSnackbarContent((
                        <Markdown>
                            {`## Failed to register:\n${message}`}
                        </Markdown>
                    ));
                })
                .finally(() => {
                    setIsRegisterButtonEnabled(true);
                    setIsSpinnerOpen(false);
                });
        },
        [
            isRegisterButtonEnabled,
            navigate,
            setIsSpinnerOpen,
            setSnackbarContent,
        ],
    );

    return (
        <Grid
            component="main"
            container={true}
            sx={{
                height: '100vh',
            }}
        >
            <Grid
                item={true}
                md={7}
                sm={4}
                sx={{
                    backgroundColor: (
                        theme => theme.palette.mode === 'light'
                            ? theme.palette.grey[50]
                            : theme.palette.grey[900]
                    ),
                    backgroundImage: `url(${background})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
                xs={false}
            />
            <Grid
                component={Paper}
                elevation={6}
                item={true}
                md={5}
                sm={8}
                square={true}
                textAlign="center"
                xs={12}
            >
                <Box
                    sx={{
                        alignContent: 'flex-start',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        mx: 4,
                        my: 1,
                    }}
                >
                    <VelocityInRoomSolutionsBanner />
                    <Typography
                        component="h1"
                        sx={{
                            textAlign: 'center',
                        }}
                        variant="h5"
                    >
                        Welcome to your Personal Network Registration Portal
                    </Typography>
                    <Typography
                        sx={{
                            mb: 1,
                            mt: 2,
                        }}
                        variant="h6"
                    >
                        {propertyName}
                    </Typography>
                    <Box
                        component="form"
                        noValidate={true}
                        onSubmit={handleSubmit}
                    >
                        <input
                            name="locationId"
                            type="hidden"
                            value={locationId}
                        />
                        <TextField
                            autoComplete="first-name"
                            autoFocus={true}
                            fullWidth={true}
                            id="firstName"
                            label="First Name"
                            margin="normal"
                            name="firstName"
                            required={true}
                        />
                        <TextField
                            autoComplete="last-name"
                            fullWidth={true}
                            id="lastName"
                            label="Last Name"
                            margin="normal"
                            name="lastName"
                            required={true}
                        />
                        <TextField
                            autoComplete="email"
                            fullWidth={true}
                            id="email"
                            label="Email Address"
                            margin="normal"
                            name="email"
                            required={true}
                        />
                        <MuiTelInput
                            autoComplete="mobile-phone-number"
                            continents={[ 'NA' ]}
                            defaultCountry="US"
                            disableDropdown={true}
                            fullWidth={true}
                            id="mobilePhoneNumber"
                            label="Mobile Phone Number"
                            margin="normal"
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            required={true}
                            value={phoneNumber}
                        />
                        <BuildingUnitAutocomplete
                            sx={{
                                mb: 1,
                                mt: 2,
                            }}
                        />
                        <Captcha siteKey={CAPTCHA_KEY} />
                        <EulaCheckbox
                            onChange={handleEulaToggleChange}
                            sx={{
                                ml: '-11px',
                                mr: '0px',
                            }}
                        />
                        <Button
                            disabled={!isRegisterButtonEnabled}
                            fullWidth={true}
                            sx={{
                                '&.MuiButton-root:hover': {
                                    backgroundColor: '#9ECC69',
                                },
                                backgroundColor: '#72C16A',
                                mb: 1,
                                mt: 2,
                            }}
                            type="submit"
                            variant="contained"
                        >
                            Register
                        </Button>
                        <Divider
                            sx={{
                                backgroundColor: '#80848C',
                                mb: 1,
                                mt: 2,
                            }}
                        />
                        <PrivacyPolicyButton />
                        <Box
                            my="10px"
                            textAlign="center"
                        >
                            <Markdown>
                                {supportInfoText}
                            </Markdown>
                        </Box>
                        <LocationAddress />
                        <Copyright
                            sx={{
                                mt: 4,
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}
