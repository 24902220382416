import {
    Checkbox,
    FormControlLabel,
    FormControlLabelProps,
    Link,
    styled,
} from '@mui/material';
import React, {
    ChangeEvent,
    ReactNode,
    useCallback,
    useState,
} from 'react';

import { Markdown } from 'src/app/components/Markdown';
import { PanPortalDialog } from 'src/app/components/PanPortalDialog';
import EULA_TEXT from 'src/app/markdown/eula.md?raw';

export type EulaCheckboxProps = Omit<
    FormControlLabelProps,
    | 'control'
    | 'label'
    | 'onChange'
> & {
    onChange: (value: boolean) => void;
};

const StyledCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
        color: '#387DC0',
    },
    color: '#26498D',
});

const StyledLink = styled(Link)({
    cursor: 'pointer',
});

export function EulaCheckbox({
    onChange,
    sx,
    ...formLabelProps
}: EulaCheckboxProps): ReactNode {
    const [ isEulaModalOpen, setIsEulaModalOpen ] = useState<boolean>(false);

    const handleCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            onChange(event.target.checked);
        },
        [
            onChange,
        ],
    );

    const handleEulaDialogClose = useCallback(
        (): void => {
            setIsEulaModalOpen(false);
        },
        [],
    );

    const handleEulaLinkClick = useCallback(
        (event: React.MouseEvent): void => {
            event.preventDefault();
            setIsEulaModalOpen(true);
        },
        [],
    );

    return (
        <>
            <FormControlLabel
                color="secondary"
                control={(
                    <StyledCheckbox
                        name="doesAgreeToEula"
                        onChange={handleCheckboxChange}
                    />
                )}
                label={(
                    <span>
                        You agree to the terms and conditions in the&nbsp;
                        <StyledLink onClick={handleEulaLinkClick}>
                            EULA
                        </StyledLink>
                        .
                    </span>
                )}
                required={true}
                sx={{
                    cursor: 'default',
                    ml: 0,
                    mr: 0,
                    ...sx,
                }}
                {...formLabelProps}
            />
            <PanPortalDialog
                onClose={handleEulaDialogClose}
                open={isEulaModalOpen}
                title="Acceptable Use Policy"
            >
                <Markdown>
                    {EULA_TEXT}
                </Markdown>
            </PanPortalDialog>
        </>
    );
}
