import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    getLocationSettingsByIpAddress,
    getLocationSettingsByLocation,
} from 'src/app/helpers/api';
import { useSetSnackbarContent } from 'src/app/hooks/useSnackbar';
import { useSetIsSpinnerOpen } from 'src/app/hooks/useSpinner';
import { VSelectLocationSettings } from 'src/types/vselect';

export type NullableLocationSettings = VSelectLocationSettings | null | undefined;

export type SetSelectedLocationIdFunction = (locationId: number) => void;

// use false to detect calls outside of `LocationSettingsProvider`
const SetSelectedLocationIdContext = createContext<SetSelectedLocationIdFunction | false>(false);

// use false to detect calls outside of `LocationSettingsProvider`
const NullableLocationSettingsContext = createContext<NullableLocationSettings | false>(false);

export function LocationSettingsProvider({
    children,
}: PropsWithChildren): ReactNode {
    const setIsSpinnerOpen = useSetIsSpinnerOpen();
    const setSnackbarContent = useSetSnackbarContent();

    const [ locationSettings, setLocationSettings ] = useState<NullableLocationSettings>(undefined);
    const [ selectedLocationId, setSelectedLocationId ] = useState<number | undefined>(undefined);

    useEffect(
        (): (() => void) => {
            setIsSpinnerOpen(true);
            const abortController = new AbortController();

            (async (): Promise<void> => {
                try {
                    const newLocationSettings = await getLocationSettingsByIpAddress(abortController.signal);

                    if (abortController.signal.aborted) {
                        return;
                    }

                    setLocationSettings(newLocationSettings);
                } finally {
                    setIsSpinnerOpen(false);
                }
            })();

            return (): void => {
                abortController.abort('Unloaded');
            };
        },
        [
            setIsSpinnerOpen,
            setLocationSettings,
        ],
    );

    useEffect(
        (): void | (() => void) => {
            if (!selectedLocationId) {
                return undefined;
            }

            setIsSpinnerOpen(true);
            const abortController = new AbortController();

            (async (): Promise<void> => {
                const newLocationSettings = await getLocationSettingsByLocation(selectedLocationId, abortController.signal);

                if (!newLocationSettings?.isEnabled) {
                    setSnackbarContent('Failed to switch location, please refresh the page.');
                    return;
                }

                setLocationSettings(newLocationSettings);
                setIsSpinnerOpen(false);
            })();

            return (): void => {
                abortController.abort('Unloaded');
            };
        },
        [
            selectedLocationId,
            setSnackbarContent,
            setIsSpinnerOpen,
            setLocationSettings,
        ],
    );

    return (
        <NullableLocationSettingsContext.Provider value={locationSettings}>
            <SetSelectedLocationIdContext.Provider value={setSelectedLocationId}>
                {children}
            </SetSelectedLocationIdContext.Provider>
        </NullableLocationSettingsContext.Provider>
    );
}

export function useNullableLocationSettings(): NullableLocationSettings {
    const locationSettings = useContext(NullableLocationSettingsContext);

    if (locationSettings === false) {
        throw new Error('`useNullableLocationSettings` must be called from within `LocationSettingsProvider`');
    }

    return locationSettings;
}

export function useLocationSettings(): VSelectLocationSettings {
    const locationSettings = useContext(NullableLocationSettingsContext);

    if (!locationSettings) {
        throw new Error('`useLocationSettings` used before location loaded');
    }

    return locationSettings;
}

export function useSetSelectedLocationId(): SetSelectedLocationIdFunction {
    const setSelectedLocationId = useContext(SetSelectedLocationIdContext);

    if (!setSelectedLocationId) {
        throw new Error('`useSetSelectedLocationId` called outside of `LocationSettingsProvider`');
    }

    return setSelectedLocationId;
}
