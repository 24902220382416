import React, {
    ReactNode,
} from 'react';

import { Markdown } from 'src/app/components/Markdown';
import { NoticePage } from 'src/app/components/NoticePage';
import SUCCESS_TEXT from 'src/app/markdown/success.md?raw';

export function Success(): ReactNode {
    return (
        <NoticePage>
            <Markdown>
                {SUCCESS_TEXT}
            </Markdown>
        </NoticePage>
    );
}
