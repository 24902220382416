import React, {
    ReactNode,
} from 'react';

import { Markdown } from 'src/app/components/Markdown';
import { NoticePage } from 'src/app/components/NoticePage';
import { RegistrationForm } from 'src/app/components/Registration/RegistrationForm';
import { useClientAddress } from 'src/app/hooks/useClientAddress';
import {
    LocationSettingsProvider,
    useNullableLocationSettings,
} from 'src/app/hooks/useLocationSettings';
import ACCESS_DENIED_TEXT from 'src/app/markdown/accessDenied.md?raw';

function RegisterScreenContent(): ReactNode {
    const clientAddress = useClientAddress();
    const locationSettings = useNullableLocationSettings();

    if (locationSettings === undefined) {
        return null;
    }

    if (
        !locationSettings
        || !locationSettings.locationId
        || !locationSettings.isEnabled
    ) {
        return (
            <NoticePage>
                <Markdown>
                    {ACCESS_DENIED_TEXT}
                </Markdown>
                <sub>
                    {clientAddress}
                </sub>
            </NoticePage>
        );
    }

    return (
        <RegistrationForm />
    );
}

export function Register(): ReactNode {
    return (
        <LocationSettingsProvider>
            <RegisterScreenContent />
        </LocationSettingsProvider>
    );
}
