import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    SyntheticEvent,
    useCallback,
    useContext,
    useState,
} from 'react';

import {
    PanPortalSnackbarAlert,
    PanPortalSnackbarAlertProps,
} from 'src/app/components/PanPortalSnackbarAlert';

export type SetSnackbarContentFunction = (content: PanPortalSnackbarAlertProps['children']) => void;

const SetSnackbarContentContext = createContext<SetSnackbarContentFunction>(() => {
    throw new Error('`useSetSnackbarContent` called outside of `SnackbarProvider`');
});

export function SnackbarProvider({
    children,
}: PropsWithChildren): ReactNode {
    const [ content, setContent ] = useState<PanPortalSnackbarAlertProps['children']>(null);

    const handleAlertClose = useCallback(
        (_event: SyntheticEvent): void => {
            setContent(null);
        },
        [],
    );

    const handleSnackbarClose = useCallback(
        (_event: SyntheticEvent | Event, reason?: string): void => {
            if (reason === 'clickaway') {
                return;
            }

            setContent(null);
        },
        [],
    );

    return (
        <>
            <SetSnackbarContentContext.Provider value={setContent}>
                {children}
            </SetSnackbarContentContext.Provider>
            <PanPortalSnackbarAlert
                alertProps={{
                    onClick: handleAlertClose,
                    onClose: handleAlertClose,
                }}
                onClose={handleSnackbarClose}
                open={content !== null}
            >
                {content}
            </PanPortalSnackbarAlert>
        </>
    );
}

export function useSetSnackbarContent(): SetSnackbarContentFunction {
    return useContext(SetSnackbarContentContext);
}
