import {
    Backdrop,
    CircularProgress,
} from '@mui/material';
import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    useContext,
    useState,
} from 'react';

export type SetIsSpinnerOpenFunction = (value: boolean) => void;

const SpinnerContext = createContext<SetIsSpinnerOpenFunction>((): void => {
    throw new Error('`useSetIsSpinnerOpen` called outside of `SpinnerProvider`');
});

export function SpinnerProvider({
    children,
}: PropsWithChildren): ReactNode {
    const [ isSpinnerOpen, setIsSpinnerOpen ] = useState<boolean>(false);

    return (
        <>
            <SpinnerContext.Provider value={setIsSpinnerOpen}>
                {children}
            </SpinnerContext.Provider>
            <Backdrop
                open={isSpinnerOpen}
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export function useSetIsSpinnerOpen(): SetIsSpinnerOpenFunction {
    return useContext(SpinnerContext);
}
