import React, {
    ReactNode,
} from 'react';
import ReactMarkdown, {
    Options,
} from 'react-markdown';
import { PluggableList } from 'react-markdown/lib';
import remarkGFM from 'remark-gfm';

import { MARKDOWN_COMPONENTS } from 'src/app/components/Markdown/components';

const remarkPlugins: PluggableList = [
    remarkGFM,
];

export type MarkdownProps = Pick<
    Options,
    | 'children'
    | 'components'
>;

export function Markdown({
    children,
    components: overrides = {},
}: MarkdownProps): ReactNode {
    return (
        <ReactMarkdown
            components={{
                ...MARKDOWN_COMPONENTS,
                ...overrides,
            }}
            remarkPlugins={remarkPlugins}
            skipHtml={true}
        >
            {children}
        </ReactMarkdown>
    );
}
