import React, {
    ReactNode,
    useEffect,
} from 'react';

export const DUMMY_KEY = '1x00000000000000000000AA';

declare global {
    interface Window {
        turnstile?: {
            render: (className: string) => string,
            remove: (widgetId?: string) => never,
            reset: (widgetId?: string) => never,
        }
    }
}

export type CaptchaProps = {
    siteKey: string,
    version?: number,
};

function CaptchaComponent({
    siteKey,
    version = 0,
}: CaptchaProps): ReactNode {
    useEffect(
        (): void | (() => void) => {
            // prevent hotloading errors
            if (window.turnstile) {
                return undefined;
            }

            const script = document.createElement('script');
            script.src = `https://challenges.cloudflare.com/turnstile/v${version}/api.js`;
            script.async = true;

            document.head.append(script);

            return (): void => {
                script.remove();
            };
        },
        [
            version,
        ],
    );

    if (!siteKey) {
        return null;
    }

    return (
        <div
            className="cf-turnstile"
            data-sitekey={siteKey}
        />
    );
}

export function Captcha({
    siteKey,
    ...props
}: CaptchaProps): ReactNode {
    useEffect(
        (): void | (() => void) => {
            if (!window.turnstile || !siteKey) {
                return undefined;
            }

            const id = window.turnstile.render('.cf-turnstile');

            return (): void => {
                if (window.turnstile) {
                    window.turnstile.remove(id);
                }
            };
        },
        [
            siteKey,
        ],
    );

    return (
        <CaptchaComponent
            siteKey={siteKey}
            {...props}
        />
    );
}
