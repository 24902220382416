import {
    Button,
    ButtonProps,
} from '@mui/material';
import React, {
    ReactNode,
    useCallback,
    useState,
} from 'react';

import { Markdown } from 'src/app/components/Markdown';
import { PanPortalDialog } from 'src/app/components/PanPortalDialog';
import PRIVACY_POLICY_TEXT from 'src/app/markdown/privacyPolicy.md?raw';

export function PrivacyPolicyButton({
    sx,
    ...buttonProps
}: ButtonProps): ReactNode {
    const [ isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen ] = useState<boolean>(false);

    const handlePrivacyPolicyDialogClose = useCallback(
        (): void => {
            setIsPrivacyPolicyDialogOpen(false);
        },
        [],
    );

    const handleViewPrivacyPolicyClick = useCallback(
        (): void => {
            setIsPrivacyPolicyDialogOpen(true);
        },
        [],
    );

    return (
        <>
            <Button
                fullWidth={true}
                onClick={handleViewPrivacyPolicyClick}
                sx={{
                    '&.MuiButton-root:hover': {
                        backgroundColor: '#387DC0',
                    },
                    backgroundColor: '#26498D',
                    mb: 1,
                    mt: 2,
                    ...sx,
                }}
                type="button"
                variant="contained"
                {...buttonProps}
            >
                View Privacy Policy
            </Button>
            <PanPortalDialog
                onClose={handlePrivacyPolicyDialogClose}
                open={isPrivacyPolicyDialogOpen}
                title="Velocity Privacy Policy"
            >
                <Markdown>
                    {PRIVACY_POLICY_TEXT}
                </Markdown>
            </PanPortalDialog>
        </>
    );
}
