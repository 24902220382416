import {
    createTheme,
    CssBaseline,
    ThemeProvider,
    // useMediaQuery,
} from '@mui/material';
import React, {
    ReactNode,
} from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';

import { Register } from 'src/app/containers/Register';
import { Success } from 'src/app/containers/Success';
import { SnackbarProvider } from 'src/app/hooks/useSnackbar';
import { SpinnerProvider } from 'src/app/hooks/useSpinner';

// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

// TODO: Create a theme, add dark mode, and follow the Velocity Branding Guidelines
// Example: https://github.com/mui/material-ui/blob/v5.15.15/docs/data/material/getting-started/templates/checkout/getCheckoutTheme.tsx
// const theme = createTheme({
//     components: {
//         MuiButton: {
//             styleOverrides: {
//                 root: () => ({
//                     ...{
//                         // color: prefersDarkMode ? 'white' : 'black',
//                     },
//                 }),
//             },
//         },
//     },
//     palette: {
//         mode: prefersDarkMode ? 'dark' : 'light',
//     },
//     typography: {
//         fontFamily: 'Arial, Helvetica, sans-serif',
//     },
// });
const theme = createTheme();

export function App(): ReactNode {
    return (
        <ThemeProvider
            theme={theme}
        >
            <CssBaseline />
            {/* TODO: Add a banner to inform the user that they're in the development system. Make it display on all pages. */}
            <SnackbarProvider>
                <SpinnerProvider>
                    <Routes>
                        <Route
                            element={<Register />}
                            index={true}
                            path="/"
                        />
                        <Route
                            element={<Success />}
                            path="success"
                        />
                        <Route
                            element={<Register />}
                            path="*"
                        />
                    </Routes>
                </SpinnerProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
