import {
    Box,
    Container,
} from '@mui/material';
import React, {
    PropsWithChildren,
    ReactNode,
} from 'react';

import { Copyright } from 'src/app/components/Copyright';
import { VelocityInRoomSolutionsBanner } from 'src/app/components/VelocityInRoomSolutionsBanner';

export function NoticePage({
    children,
}: PropsWithChildren): ReactNode {
    return (
        <Container
            component="main"
            maxWidth="md"
            sx={{
                alignContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'space-between',
                textAlign: 'center',
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    my: 1,
                }}
            >
                <VelocityInRoomSolutionsBanner />
                {children}
            </Box>
            <Copyright
                sx={{
                    my: 1,
                }}
            />
        </Container>
    );
}
