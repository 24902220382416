import { API_URL } from 'src/app/config';
import {
    ClientInfo,
    CreateCloudpathPropertyUnitBody,
} from 'src/types/api';
import { CloudpathUnit } from 'src/types/cloudpath';
import {
    VSelectLocationSettings,
    VSelectSiteName,
} from 'src/types/vselect';

export async function getAvailableSites(signal: AbortSignal): Promise<VSelectSiteName[] | null> {
    try {
        const response = await fetch(`${API_URL}/api/available-sites`, { signal });

        return await response.json();
    } catch {
        return null;
    }
}

export async function getClientInfo(): Promise<ClientInfo | null> {
    try {
        const response = await fetch(`${API_URL}/api/client-info`);

        if (!response.ok) {
            throw new Error('Failed to retrieve client info.');
        }

        return await response.json();
    } catch {
        return null;
    }
}

export async function getLocationSettingsByIpAddress(signal: AbortSignal): Promise<VSelectLocationSettings | null> {
    try {
        const response = await fetch(`${API_URL}/api/location-settings`, { signal });

        if (!response.ok) {
            throw new Error("Your location's IP Address does not have access to this resource.");
        }

        const json = await response.json();
        console.log(JSON.stringify(
            {
                ...json,
                dateTime: (new Date()).toISOString(),
            },
            null,
            4,
        ));
        return json;
    } catch (err) {
        if (!signal.aborted) {
            console.error(err);
        }
        return null;
    }
}

export async function getLocationSettingsByLocation(locationId: number, signal: AbortSignal): Promise<VSelectLocationSettings | null> {
    try {
        const response = await fetch(`${API_URL}/api/location-settings/${locationId}`, { signal });

        return await response.json();
    } catch {
        return null;
    }
}

export async function createCloudpathPropertyUnit(body: CreateCloudpathPropertyUnitBody): Promise<CloudpathUnit | null> {
    try {
        const response = await fetch(
            `${API_URL}/api/cloudpath/property/unit`,
            {
                body: JSON.stringify(body),
                method: 'POST',
            },
        );

        if (!response.ok) {
            throw new Error('An error occurred while attempting to register a new unit.');
        }

        return await response.json();
    } catch (err) {
        console.error(err);
        return null;
    }
}
