import {
    Link,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { Components } from 'react-markdown';

const Image = styled('img')({
    maxWidth: '100%',
});

export const MARKDOWN_COMPONENTS: Components = {
    a({
        children,
        href,
    }) {
        return (
            <Link href={href}>
                {children}
            </Link>
        );
    },
    h1({ children }) {
        return (
            <Typography
                component="h1"
                sx={{
                    my: 2,
                }}
                variant="h5"
            >
                {children}
            </Typography>
        );
    },
    h2({ children }) {
        return (
            <Typography
                component="h2"
                gutterBottom={true}
                variant="h6"
            >
                {children}
            </Typography>
        );
    },
    img({
        alt,
        src,
    }) {
        if (!src) {
            return null;
        }

        const [
            url,
            searchParams,
        ] = src.split('?');
        const params = new URLSearchParams(searchParams);
        const height = params.get('height') || undefined;
        const width = params.get('width') || undefined;
        return (
            <Image
                alt={alt}
                height={height}
                src={url}
                width={width}
            />
        );
    },
    p({ children }) {
        return (
            <Typography
                sx={{
                    mb: 2,
                    mt: 0,
                }}
            >
                {children}
            </Typography>
        );
    },
};
