import {
    Autocomplete,
    AutocompleteRenderInputParams,
    TextField,
} from '@mui/material';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { getAvailableSites } from 'src/app/helpers/api';
import { useSetSelectedLocationId } from 'src/app/hooks/useLocationSettings';
import { VSelectSiteName } from 'src/types/vselect';

const defaultValue: VSelectSiteName = {
    locationId: -1,
    site: 'Corporate',
};

export function LocationSelect(): ReactNode {
    const setSelectedLocationId = useSetSelectedLocationId();

    const [ options, setOptions ] = useState<VSelectSiteName[]>([ defaultValue ]);

    useEffect(
        (): (() => void) => {
            const abortController = new AbortController();

            (async (): Promise<void> => {
                const availableSites = await getAvailableSites(abortController.signal);

                if (!availableSites) {
                    return;
                }

                availableSites.sort((a, b) => a.site.localeCompare(b.site));
                availableSites.unshift(defaultValue);

                setOptions(availableSites);
            })();

            return (): void => {
                abortController.abort('Unloaded');
            };
        },
        [],
    );

    const handleChange = useCallback(
        (_event: any, value: VSelectSiteName): void => setSelectedLocationId(value.locationId),
        [
            setSelectedLocationId,
        ],
    );

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams): ReactNode => (
            <TextField
                {...params}
                required={true}
                variant="standard"
            />
        ),
        [],
    );

    return (
        <Autocomplete
            clearIcon={false}
            defaultValue={defaultValue}
            disableClearable={true}
            getOptionLabel={option => option.site}
            isOptionEqualToValue={(option, value) => option.locationId === value.locationId}
            onChange={handleChange}
            options={options}
            renderInput={renderInput}
            sx={{
                width: 150,
            }}
        />
    );
}
