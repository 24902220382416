import {
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Slide,
    SlideProps,
} from '@mui/material';
import React, {
    forwardRef,
    ReactNode,
    Ref,
} from 'react';

export type PanPortalDialogProps = DialogProps & {
    title: string,
};

const SlideTransition = forwardRef((
    props: SlideProps,
    ref: Ref<unknown>,
): ReactNode => (
    <Slide
        direction="up"
        ref={ref}
        {...props}
    />
));

SlideTransition.displayName = 'SlideTransition';

export function PanPortalDialog({
    children,
    title,
    ...dialogProps
}: PanPortalDialogProps): ReactNode {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            scroll="paper"
            TransitionComponent={SlideTransition}
            {...dialogProps}
        >
            <DialogTitle
                component="h1"
            >
                {title}
            </DialogTitle>
            <DialogContent dividers={true}>
                {children}
            </DialogContent>
        </Dialog>
    );
}
