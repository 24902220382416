import {
    Grid,
    GridProps,
    Typography,
} from '@mui/material';
import React, {
    ReactNode,
    useState,
} from 'react';

import { LocationSelect } from 'src/app/components/Registration/LocationSelect';
import { useLocationSettings } from 'src/app/hooks/useLocationSettings';

export function LocationAddress({
    sx,
    ...gridProps
}: GridProps): ReactNode {
    const {
        address,
        city,
        locationId,
        site,
        state,
        zip,
    } = useLocationSettings();

    // We only care whether the *initial* location is a corporate location
    const [ isCorporateLocation ] = useState<boolean>(locationId === -1);

    return (
        <Grid
            alignItems="center"
            container={true}
            direction="column"
            justifyContent="flex-start"
            sx={{
                mb: 1,
                mt: 2,
                ...sx,
            }}
            {...gridProps}
        >
            <Grid item={true}>
                {isCorporateLocation ? (
                    <LocationSelect />
                ) : (
                    <Typography variant="body2">
                        {site}
                    </Typography>
                )}
            </Grid>
            <Grid item={true}>
                <Typography variant="body2">
                    {address}
                </Typography>
            </Grid>
            <Grid item={true}>
                <Typography variant="body2">
                    {`${city}, ${state} ${zip}`}
                </Typography>
            </Grid>
        </Grid>
    );
}
