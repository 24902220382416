import {
    Autocomplete,
    AutocompleteProps,
    AutocompleteRenderInputParams,
    TextField,
} from '@mui/material';
import React, {
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';

import { useLocationSettings } from 'src/app/hooks/useLocationSettings';

export type BuildingUnitAutocompleteProps = Omit<
    AutocompleteProps<string, false, false, false>,
    | 'onChange'
    | 'options'
    | 'renderInput'
    | 'value'
>;

export function BuildingUnitAutocomplete({
    defaultValue = null,
    ...autocompleteProps
}: BuildingUnitAutocompleteProps): ReactNode {
    const [ selectedUnit, setSelectedUnit ] = useState<string | null>(defaultValue);

    const { buildingUnitList } = useLocationSettings();

    const handleChange = useCallback(
        (_event: any, newValue: string | null) => {
            setSelectedUnit(newValue);
        },
        [],
    );

    const renderBuildingUnitInput = useCallback(
        (params: AutocompleteRenderInputParams): ReactNode => (
            <TextField
                {...params}
                label="Building / Unit"
                name="buildingUnit"
                required={true}
            />
        ),
        [],
    );

    const buildingUnitOptions = useMemo(
        (): string[] => {
            const newBuildingUnitOptions = buildingUnitList
                .replaceAll(/[\t ]+/g, ' ')
                .split('\n');

            setSelectedUnit(prev => {
                if (prev && !newBuildingUnitOptions.includes(prev)) {
                    return null;
                }

                return prev;
            });

            return newBuildingUnitOptions;
        },
        [
            buildingUnitList,
        ],
    );

    return (
        <Autocomplete
            fullWidth={true}
            id="buildingUnit"
            options={buildingUnitOptions}
            renderInput={renderBuildingUnitInput}
            {...autocompleteProps}
            onChange={handleChange}
            value={selectedUnit}
        />
    );
}
